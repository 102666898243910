import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "How to Jest Test By Mocking Imports",
  "author": "tzookb",
  "type": "post",
  "date": "2020-01-01T13:50:08.000Z",
  "url": "/jest-testing",
  "featuredImage": "./thumbnail.jpg",
  "categories": ["javascript", "testing", "jest"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <br />
Jest is one of the top libraries for running javascript tests.
    <p>{`It's great for unit tests, integrations, and more.`}</p>
    <p>{`The single little pain I encounter each time I work with Jest is when I need to mock imports.`}</p>
    <p>{`Now when I'm talking about imports there two types: old-style exports and ES6 export.
i.e.:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`module.exports = theModule
module.exports.another = anotherModule
// vs
export default = theModule
export const another = anotherModule
`}</code></pre>
    <p>{`So let's say I have a module that I want to test.
The module uses another module that depends on some 3rd party service.
In my tests, I don't want to be dependent on the 3rd party service for the success of my tests. `}</p>
    <h3>{`Why is that?`}</h3>
    <p>{`Let's say the 3rd party service is down for some reason. That means my tests will fail each time until their service is functional again.`}</p>
    <h3>{`Example`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// dependency.js
import fetchData from 'third-party-service'

export default async _ => {
  const data = await fetchData()
  return {
    name: data.defaultName,
    score: data.score,
  }
}

export const namedExport = async name => {
  const data = await fetchData(name)
  return {
    name: data.name,
    score: data.score,
  }
}
`}</code></pre>
    <p>{`As you can see above we have two methods that fetch data from a 3rd party service.
One method is the default one for the package and the other is named.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// testSubject.js
import defaultImport, { namedExport } from './dependency'

export const returnDefaultImport = _ => {  
  return defaultImport()
}

export const returnNamedImport = _ => {
  return namedExport()
}
`}</code></pre>
    <p>{`In our test subject here it uses our dependency module for getting data.
It depends on our dependency and not the 3rd party.`}</p>
    <h3>{`The Tests`}</h3>
    <p>{`Jest testing with imports was always a hard thing that was hard for me to solve each time.
I'm quite sure that are better ways to do what I'm doing, and I probably have some mistakes.`}</p>
    <p>{`But, it will surely give you a head start of moving forward.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { returnDefaultImport returnNamedImport } from './testSubject'
jest.mock('./dependency')
import defaultExport, * as theRest from './dependency'

describe('mock default', () => {
  beforeEach(() => {
    jest.resetModules()
    defaultExport.mockClear()
  })

  it('work', () => {
    defaultExport.mockImplementation(() => {
      return 'mockedDefaultMethod'
    })
    theRest.namedExport.mockImplementation(() => {
      return 'mockedNameMethod'
    })
    expect(returnDefaultImport()).toBe('mockedDefaultMethod')
    expect(returnNamedImport()).toBe('mockedNameMethod')
  })
})
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      